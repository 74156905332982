'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitGroupUserCtrl

 # @description

###
class UnitGroupUserCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitGroupUsersManager
    UnitGroupsManager
    _
    $q
    $log
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @items = []


      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit
      @edit = edit

      @isExistingItem = false

      @load()

    ##########

    add = ->
      @progress = true

      if @isExistingItem
        UnitGroupUsersManager.setParentId(@id)
        $q.when(UnitGroupUsersManager.delete(@model.id))
        @isExistingItem = false

      UnitGroupUsersManager.setParentId(@id)
      $q.when(UnitGroupsManager.addUser(@id, @model)).then =>
        @model = {}
        @load()


    remove = (item) ->
      @progress = true
      UnitGroupUsersManager.setParentId(@id)
      $q.when(UnitGroupUsersManager.delete(item.id)).then =>
        @load()

    edit = (item) ->
      @items.splice(@items.indexOf(item),1)
      @model = item
      @isExistingItem = true

    load = ->
      @fields = []
      UnitGroupsManager.one(@id)
        .then (data) =>
          @unitGroupLabel = data.label

      UnitGroupUsersManager.setParentId(@id)
      UnitGroupUsersManager.getFullList().then((users) =>
        existingUserIds = []

        @items = []
        for user in users
          UnitGroupUsersManager.one(user.id).then((completeUser) =>
            $log.debug completeUser
            if !completeUser.deletedAt
              @items.push(completeUser)

              #save all usercontext ids, these do not have to show up in the dropdown
              existingUserIds.push completeUser.userContext.id
              if (existingUserIds.length == users.length)
                @fields = UnitGroupUsersManager.getForm(existingUserIds)
          )
        if users? && (!(angular.isArray users) || (users.length == 0))
          @fields = UnitGroupUsersManager.getForm(existingUserIds)

        @progress = false

        @form = {}
      )
    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitGroupUserCtrl', UnitGroupUserCtrl
